import React, { useState, useEffect, useCallback, memo, useContext, useMemo } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import { Modal } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import captureError from '../utils/sentry';
import Boundary from '../components/Boundary';
import { Card, Flex, Button, Loader, CloseButton, Icon } from '../components/primitives';
import {
  getDashboardFeedPaginated,
  getDashboardFeedPaginatedV2,
  getUserWatchlistFeedPaginated,
  getVerticalPriorityFeedPaginated,
} from '../BackendAPI';
import { getUserFirstName, getUserEmail } from '../utils/Auth';
import DashboardSidebar from '../components/dashboard/DashboardSidebar';
import UserActivityFeed from '../components/dashboard/UserActivityFeed';
import DealFeedSidebar from '../components/dashboard/DealFeedSidebar';
import {
  AdvancedSearchV2Context,
  AdvancedSearchV2Provider,
} from '../components/advancedSearch/AdvancedSearchV2Context';
import { buildRouteQueryParams } from '../components/advancedSearch/AdvancedSearchV2Header';
import { isRecent } from '../util';
import PageHeader from '../components/PageHeader';
import { getUserId } from '../utils/Auth2';
import FeedFilter from '../components/FeedFilter';
import { parseParams } from './AdvancedSearch';
import useIsMobile from '../hooks/useIsMobile';

import '../styles/components/Dashboard.css';

const AMPLITUDE_API_KEY = '9394220f61d6405247e15adb9f4acbeb';

const GREETINGS = [
  'Howdy',
  'Hello',
  'Hola',
  'Bonjour',
  'Guten Tag',
  'Hallo',
  'Hei',
  'Aloha',
  'Nǐ hǎo',
  "Kon'nichiwa",
];

const LeftColumn = styled.div`
  padding-left: 50px;
  padding-right: 10px;
  @media (max-width: 991px) {
    padding-left: 10px;
  }
`;

const MiddleColumn = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  width: 265px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const RightColumn = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Container = styled.div`
  flex-wrap: nowrap;
  @media (max-width: 991px) {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
  }
`;

const StyledForm = styled.form`
  margin-left: 20px;
  .form-checkbox-row {
    &:last-child {
      margin-bottom: 0;
    }
    .form-check {
      margin: 0;
      .form-check-label i {
        top: 4px;
        p {
          margin-bottom: 0;
        }
      }
      .form-check-label > i.checked {
        display: block;
      }
    }
  }
`;

const getGreeting = () => {
  const idx = Math.floor(GREETINGS.length * Math.random());
  return `${GREETINGS[idx]}, ${getUserFirstName()} 👋`;
};

const ActivityFeedModal = ({ showModal, toggleModal, activeFeedType, handleFeedTypeChange }) => {
  const getRadioClassnames = useCallback(
    (val) => {
      return activeFeedType === val ? 'fa fa-check-circle checked' : 'fa fa-circle-thin unchecked';
    },
    [activeFeedType],
  );

  return (
    <Modal
      backdrop="static"
      show={showModal}
      onHide={() => toggleModal(false)}
      className="tv-modal"
    >
      <Modal.Header>
        <Modal.Title>Activity Feed</Modal.Title>
        <CloseButton className="modal-close" onClick={() => toggleModal(false)} />
      </Modal.Header>
      <Modal.Body>
        <Container>
          <StyledForm>
            <ul className="list-unstyled list-spaced">
              <li className="form-checkbox-row">
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={activeFeedType === 'default'}
                      value="default"
                      onChange={handleFeedTypeChange}
                    />
                    <p>Default</p>
                    <i className={getRadioClassnames('default')} aria-hidden="true" />
                  </label>
                </div>
              </li>
              <li className="form-checkbox-row">
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={activeFeedType === 'watchlist'}
                      value="watchlist"
                      onChange={handleFeedTypeChange}
                    />
                    <p>Watchlist</p>
                    <i className={getRadioClassnames('watchlist')} aria-hidden="true" />
                  </label>
                </div>
              </li>
              <li className="form-checkbox-row">
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={activeFeedType === 'vertical-priority'}
                      value="vertical-priority"
                      onChange={handleFeedTypeChange}
                    />
                    <p>Vertical Priority</p>
                    <i className={getRadioClassnames('vertical-priority')} aria-hidden="true" />
                  </label>
                </div>
              </li>
            </ul>
          </StyledForm>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

function DashboardErrorFallback({ refreshFeed, isInDashboardV2, toggleModal }) {
  return (
    <div className="company-panel-feed">
      <Flex className="section-header widget" justify="space-between">
        <h6>Activity Feed</h6>
        <Flex>{isInDashboardV2 && <Icon name="eye-open" onClick={() => toggleModal(true)} />}</Flex>
      </Flex>{' '}
      <Card className="dashboard-error" align="center" justify="center">
        <Flex direction="column" align="center" justify="center" fill>
          <h5>There was an issue loading your dashboard feed</h5>
          <p>Retry and if the issue perists contact the IT team.</p>
          {refreshFeed && <Button onClick={() => refreshFeed(true)}>Retry</Button>}
        </Flex>
      </Card>
    </div>
  );
}

function DashboardLoadingFallback({ isInDashboardV2, toggleModal }) {
  return (
    <div className="company-panel-feed">
      <Flex className="section-header widget" justify="space-between">
        <h6>Activity Feed</h6>
        <Flex>{isInDashboardV2 && <Icon name="eye-open" onClick={() => toggleModal(true)} />}</Flex>
      </Flex>
      <Flex
        align="center"
        justify="center"
        direction="column"
        fill
        className="page-loader user-feed"
      >
        <FeedFilter
          withFeedTypeFilter
          handleFeedTypeChange={() => {}}
          withVerticalFilter
          handleOnVerticalChange={() => {}}
          isDashboard
          padding="20px"
          margin="0"
          borderRadius={false}
          boxShadow={false}
        />
        <Flex
          align="center"
          justify="center"
          direction="column"
          fill
          className="page-loader list-table card"
        >
          <Loader />
        </Flex>
      </Flex>
    </div>
  );
}

function DashboardContent() {
  const [error, setError] = useState(false);
  const [activeFeedType, setActiveFeedType] = useState('default'); // default, watchlist, vertical-priority
  const [state] = useContext(AdvancedSearchV2Context);
  const params = buildRouteQueryParams(state);
  const [loading, setLoading] = useState(false);
  const userEmail = getUserEmail();
  const [showModal, toggleModal] = useState(false);
  window.history.replaceState({}, '', `?${params}`);

  const [defaultFeed, setDefaultFeed] = useState(null);
  const [defaultFeedPageIndex, setDefaultFeedPage] = useState(1);

  const [watchlistFeed, setWatchlistFeed] = useState(null);
  const [watchlistFeedPageIndex, setWatchlistFeedPage] = useState(1);

  const [verticalPriorityFeed, setVerticalPriorityFeed] = useState(null);
  const [verticalFeedPageIndex, setVerticalFeedPage] = useState(1);

  const isInDashboardV2 = userEmail === 'colin@thomvest.com' || userEmail === 'nima@thomvest.com';
  const [localLimit] = useState(12);

  useEffect(() => {
    const email = getUserEmail();
    amplitude.init(AMPLITUDE_API_KEY);
    amplitude.setUserId(email);
  }, []);

  const fetchDefaultFeed = useCallback(
    async (reset = false) => {
      try {
        setLoading(true);
        const page = reset ? 1 : defaultFeedPageIndex;
        const limit = localLimit;
        const userId = await getUserId();

        let dashboardFeed = [];

        if (isInDashboardV2) {
          dashboardFeed = await getDashboardFeedPaginatedV2({ page, limit });
        } else {
          dashboardFeed = await getDashboardFeedPaginated({ userId, page, limit });
        }

        const filteredFeed =
          dashboardFeed
            .map((f) => {
              f.isRecent = isRecent(f.feed_time);
              return f;
            })
            .filter((f) => f.type !== 'list') ?? [];

        const resetFeed = reset ? [...filteredFeed] : [...(defaultFeed ?? []), ...filteredFeed];
        setDefaultFeed(resetFeed);

        const resetPage = reset ? page : page + 1;
        setDefaultFeedPage(resetPage);
      } catch (err) {
        captureError(`Dashboard feed failed: ${err}`);
        setError(true);
      } finally {
        setLoading(false);
      }
    },
    [defaultFeed, localLimit, defaultFeedPageIndex, isInDashboardV2],
  );

  const fetchWatchlistFeed = useCallback(
    async (reset = false) => {
      try {
        setLoading(true);
        const page = reset ? 1 : watchlistFeedPageIndex;
        const limit = localLimit;
        const userId = await getUserId();

        const dashboardFeed = await getUserWatchlistFeedPaginated({ userId, page, limit });

        const filteredWatchlistFeed =
          dashboardFeed
            .map((f) => {
              f.isRecent = isRecent(f.feed_time);
              return f;
            })
            .filter((f) => f.type !== 'list') ?? [];

        const resetFeed = reset
          ? [...filteredWatchlistFeed]
          : [...(watchlistFeed ?? []), ...filteredWatchlistFeed];

        setWatchlistFeed(resetFeed);

        const resetPage = reset ? page : page + 1;
        setWatchlistFeedPage(resetPage);
        setError(false);
      } catch (err) {
        captureError(`Dashboard watchlist feed failed: ${err}`);
      } finally {
        setLoading(false);
      }
    },
    [localLimit, watchlistFeed, watchlistFeedPageIndex],
  );

  const fetchVerticalPriorityFeed = useCallback(
    async (reset = false) => {
      try {
        setLoading(true);
        const page = reset ? 1 : verticalFeedPageIndex;
        const limit = localLimit;

        const dashboardFeed = await getVerticalPriorityFeedPaginated({ page, limit });

        const filteredVerticalFeed =
          dashboardFeed
            .map((f) => {
              f.isRecent = isRecent(f.feed_time);
              return f;
            })
            .filter((f) => f.type !== 'list') ?? [];

        const resetFeed = reset
          ? [...filteredVerticalFeed]
          : [...(verticalPriorityFeed ?? []), ...filteredVerticalFeed];

        setVerticalPriorityFeed(resetFeed);

        const resetPage = reset ? page : page + 1;
        setVerticalFeedPage(resetPage);
      } catch (err) {
        captureError(`Dashboard watchlist feed failed: ${err}`);
      } finally {
        setLoading(false);
      }
    },
    [localLimit, verticalPriorityFeed, verticalFeedPageIndex],
  );

  useEffect(() => {
    // getWatchlistCompanies();
    fetchDefaultFeed();
    if (isInDashboardV2) {
      fetchWatchlistFeed();
      fetchVerticalPriorityFeed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshFeed = useCallback(
    (reset = false) => {
      setError(false);
      if (activeFeedType === 'wathlist') {
        fetchWatchlistFeed(reset);
      } else if (activeFeedType === 'vertical-priority') {
        fetchVerticalPriorityFeed(reset);
      } else {
        fetchDefaultFeed(reset);
      }
    },
    [activeFeedType, fetchDefaultFeed, fetchVerticalPriorityFeed, fetchWatchlistFeed],
  );

  const feed = useMemo(() => {
    if (activeFeedType === 'watchlist') {
      return watchlistFeed;
    }
    if (activeFeedType === 'vertical-priority') {
      return verticalPriorityFeed;
    }
    return defaultFeed;
  }, [activeFeedType, defaultFeed, verticalPriorityFeed, watchlistFeed]);

  const handleFeedTypeChange = useCallback((e) => {
    setActiveFeedType(e.target.value);
    toggleModal(false);
  }, []);

  if (error) {
    return (
      <DashboardErrorFallback
        refreshFeed={refreshFeed}
        isInDashboardV2={isInDashboardV2}
        toggleModal={toggleModal}
      />
    );
  }

  if (feed === null) {
    return <DashboardLoadingFallback isInDashboardV2={isInDashboardV2} toggleModal={toggleModal} />;
  }

  return (
    <div className="company-panel-feed">
      <Flex className="section-header widget" justify="space-between">
        <h6>Activity Feed</h6>
        <Flex>{isInDashboardV2 && <Icon name="eye-open" onClick={() => toggleModal(true)} />}</Flex>
      </Flex>
      <UserActivityFeed
        feed={feed}
        loading={loading}
        refreshFeed={() => refreshFeed()}
        watchlistCompanies={[]}
      />
      <ActivityFeedModal
        showModal={showModal}
        toggleModal={toggleModal}
        activeFeedType={activeFeedType}
        handleFeedTypeChange={handleFeedTypeChange}
      />
    </div>
  );
}

const Dashboard = () => {
  const [mobileNav, setMobileNav] = useState('feed');
  const location = useLocation();
  const params = parseParams(location);
  const isMobile = useIsMobile();

  return (
    <AdvancedSearchV2Provider params={params}>
      <div>
        <Helmet>
          <title>Dashboard - TV CRM</title>
        </Helmet>

        <PageHeader title={getGreeting()} />

        <div className="mobile-selector">
          <ul>
            <li className={mobileNav === 'feed' ? 'active' : ''}>
              <button type="button" onClick={() => setMobileNav('feed')}>
                Feed
              </button>
            </li>
            <li className={mobileNav === 'sidebar' ? 'active' : ''}>
              <button type="button" onClick={() => setMobileNav('sidebar')}>
                Sidebar
              </button>
            </li>
            <li className={mobileNav === 'deal-feed' ? 'active' : ''}>
              <button type="button" onClick={() => setMobileNav('deal-feed')}>
                Deal Feed
              </button>
            </li>
          </ul>
        </div>
        <Container className="row container dashboard-container" isMobile={isMobile}>
          <LeftColumn className={`feed${mobileNav === 'feed' ? '' : ' hidden-mobile'} col-md-6`}>
            <Boundary
              loadingFallback={<DashboardLoadingFallback />}
              fallback={<DashboardErrorFallback />}
            >
              <DashboardContent />
            </Boundary>
          </LeftColumn>
          <MiddleColumn
            className={`sidebar${mobileNav === 'sidebar' ? '' : ' hidden-mobile'} col-md-3`}
          >
            <DashboardSidebar />
          </MiddleColumn>
          <RightColumn
            className={`sidebar${mobileNav === 'deal-feed' ? '' : ' hidden-mobile'} col-md-3`}
          >
            <DealFeedSidebar />
          </RightColumn>
        </Container>
      </div>
    </AdvancedSearchV2Provider>
  );
};

export default memo(Dashboard);
