import React from 'react';
import {
  AlertIcon,
  DropdownArrowIcon,
  DropdownTriangleIcon,
  EyeOpenIcon,
  GoogleIcon,
  HelpIcon,
  InfoIcon,
  DashboardIcon,
  DownloadIcon,
  CopyIcon,
  PercentageIcon,
  RightArrowIcon,
  SearchIcon,
  SettingsIcon,
  MinusIcon,
  PlusIcon,
  LogoutIcon,
  DescriptionIcon,
  PrevArrowIcon,
  NextArrowIcon,
  CheckMarkIcon,
  DeleteIcon,
  LightningBoltIcon,
  PlusBasicIcon,
  TrashIcon,
  DotsIcon,
  CloseIcon,
  SecureIcon,
  LockedIcon,
  PlusSolidIcon,
  EmailIcon,
  ChevronDownIcon,
  FilterIcon,
  GridIcon,
  ListIcon,
  ExpandIcon,
  ExpandCloseIcon,
  EditIcon,
  LinkIcon,
} from './icons';

const getIcon = (props) => {
  switch (props.name) {
    case 'alert-icon':
      return <AlertIcon {...props} />;
    case 'check-mark-icon':
    case 'checkmark-icon':
    case 'check-mark':
    case 'checkmark':
      return <CheckMarkIcon {...props} />;
    case 'delete-icon':
      return <DeleteIcon {...props} />;
    case 'dropdown-arrow-icon':
      return <DropdownArrowIcon {...props} />;
    case 'dropdown-triangle-icon':
      return <DropdownTriangleIcon {...props} />;
    case 'eye-open':
      return <EyeOpenIcon {...props} />;
    case 'expand':
      return <ExpandIcon {...props} />;
    case 'expand-close':
      return <ExpandCloseIcon {...props} />;
    case 'edit':
      return <EditIcon {...props} />;
    case 'email-icon':
    case 'email':
      return <EmailIcon {...props} />;
    case 'google-icon':
      return <GoogleIcon {...props} />;
    case 'help-icon':
      return <HelpIcon {...props} />;
    case 'info-icon':
      return <InfoIcon {...props} />;

    case 'dashboard-icon':
      return <DashboardIcon {...props} />;
    case 'copy-icon':
      return <CopyIcon {...props} />;
    case 'download-icon':
      return <DownloadIcon {...props} />;
    case 'filter-icon':
    case 'filter':
      return <FilterIcon {...props} />;
    case 'list-icon':
    case 'list':
      return <ListIcon {...props} />;
    case 'grid-icon':
    case 'grid':
      return <GridIcon {...props} />;
    case 'locked-icon':
    case 'locked':
      return <LockedIcon {...props} />;
    case 'percentage-icon':
      return <PercentageIcon {...props} />;
    case 'right-arrow-icon':
      return <RightArrowIcon {...props} />;
    case 'lightning-icon':
    case 'lightning-bolt-icon':
      return <LightningBoltIcon {...props} />;
    case 'search-icon':
      return <SearchIcon {...props} />;
    case 'secure-icon':
    case 'shield-icon':
    case 'secure':
    case 'shield':
      return <SecureIcon {...props} />;
    case 'settings-icon':
      return <SettingsIcon {...props} />;
    case 'minus-icon':
      return <MinusIcon {...props} />;
    case 'plus-icon':
    case 'plus':
      return <PlusIcon {...props} />;
    case 'plus-solid':
      return <PlusSolidIcon {...props} />;
    case 'plus-basic-icon':
      return <PlusBasicIcon {...props} />;
    case 'trash-icon':
    case 'trashcan-icon':
      return <TrashIcon {...props} />;
    case 'property-description-icon':
      return <DescriptionIcon {...props} />;
    case 'prev-arrow-icon':
      return <PrevArrowIcon {...props} />;
    case 'next-arrow-icon':
      return <NextArrowIcon {...props} />;
    case 'dots-icon':
    case 'ellipsis-icon':
      return <DotsIcon {...props} />;
    case 'logout-icon':
    case 'logout':
      return <LogoutIcon {...props} />;
    case 'close-icon':
    case 'close':
      return <CloseIcon {...props} />;
    case 'chevron-down':
    case 'chevron-down-icon':
      return <ChevronDownIcon {...props} />;
    case 'link':
    case 'link-icon':
      return <LinkIcon {...props} />;
    default:
      return null;
  }
};

export default getIcon;
