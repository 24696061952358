import React, { useReducer, useState, useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { getVerticalPriorityCompanies, getUserWatchlistCompanies, getUsers } from '../BackendAPI';
import { getLocalValue, AUTH_TOKEN_KEY } from '../utils/Auth';
import ListCompanyTable from '../components/ListCompanyTable';
import { VERTICAL_FILTERS } from './Engaged';
import { Button } from '../components/primitives';
import useIsMobile from '../hooks/useIsMobile/useIsMobile';
import { convertToCSV, downloadCSV, getToday } from '../util';

import '../styles/components/Watchlist.css';
import PageHeader from '../components/PageHeader';
import FeedFilter from '../components/FeedFilter';

const VERTICAL_PRIORITIES = 'Vertical Priorities';
const MY_WATCHLIST = 'My Watchlist';
const TOGGLE = 'TOGGLE';
const UPDATE_COMPANIES = 'UPDATE_COMPANIES';

const WATCHLIST_COLUMNS = [
  { colClass: 'dateAdded', text: 'Date Added', key: 'date_added', sortable: true },
  { colClass: 'name', text: 'Company', key: 'company_name', sortable: true },
  { colClass: 'vertical', text: 'Vertical', key: 'tv_vertical', sortable: true },
  { colClass: 'subvertical', text: 'Sub-Vertical', key: 'subvertical', sortable: true },
  {
    colClass: 'daysOutstanding',
    text: 'VC Score',
    key: 'tv_company_investor_score',
    sortable: true,
  },
  { colClass: 'investors', text: 'Key Investors', key: 'key_investors', sortable: false },
  { colClass: 'stage', text: 'Last Round', key: 'last_investment_type', sortable: true },
  {
    colClass: 'monthsSinceInvestment',
    text: 'Round Date',
    key: 'months_since_last_investment',
    sortable: true,
  },
  {
    colClass: 'location',
    text: 'Location',
    key: 'location',
    sortable: true,
  },
  {
    colClass: 'myMemos',
    text: 'My Memos',
    key: 'my_memos',
    sortable: false,
  },
];

const VERTICAL_PRIORITY_COLUMNS = [
  {
    colClass: 'dateAdded',
    text: 'Date Added',
    key: 'vertical_priority_date_added',
    sortable: true,
  },
  { colClass: 'added_by', text: 'Added By', key: 'vertical_priority_added_by', sortable: true },
  { colClass: 'name', text: 'Company', key: 'company_name', sortable: true },
  { colClass: 'vertical', text: 'Vertical', key: 'tv_vertical', sortable: true },
  { colClass: 'subvertical', text: 'Sub-Vertical', key: 'subvertical', sortable: true },
  { text: 'Status', colClass: 'status', key: 'major_status', sortable: true },
  {
    colClass: 'daysOutstanding',
    text: 'VC Score',
    key: 'tv_company_investor_score',
    sortable: true,
  },
  { colClass: 'investors', text: 'Key Investors', key: 'key_investors', sortable: false },
  { colClass: 'stage', text: 'Last Round', key: 'last_investment_type', sortable: true },
  {
    colClass: 'monthsSinceInvestment',
    text: 'Round Date',
    key: 'months_since_last_investment',
    sortable: true,
  },
  {
    colClass: 'location',
    text: 'Location',
    key: 'location',
    sortable: true,
  },
];

const initialState = [
  {
    label: MY_WATCHLIST,
    numCompanies: 0,
    companies: [],
    active: true,
    type: 'watchlist',
  },
  {
    label: VERTICAL_PRIORITIES,
    numCompanies: 0,
    companies: [],
    active: false,
    type: 'vertical',
  },
];

const calcMonthsSinceInvestment = (d) => {
  if (!d) return 'N/A';
  const today = new Date();
  const dt = new Date(d.slice(0, 10));
  const numMonths = Math.floor((today - dt) / (1000 * 60 * 60 * 24 * 30));
  return numMonths;
};

const reducer = (state, action) => {
  const newState = [...state];
  switch (action.type) {
    case TOGGLE:
      newState[0].active = !newState[0].active;
      newState[1].active = !newState[1].active;
      return newState;
    case UPDATE_COMPANIES:
      // eslint-disable-next-line no-case-declarations
      const { companies, index } = action.payload;
      newState[index].companies = companies;
      newState[index].numCompanies = companies.length;
      return newState;
    default:
      return [...state];
  }
};

const Container = styled.div`
  .table-container {
    width: 100%;
    position: relative;

    .column {
      &.description,
      &.investors {
        width: 20%;
        min-width: 200px;
      }
      &.status {
        min-width: 130px;
      }
    }
  }

  @media (max-width: 991px) {
    margin-top: 70px;
  }
`;

const downloadWatchlist = async (watchlist) => {
  const today = getToday();
  const csvData = convertToCSV(watchlist);
  downloadCSV(csvData, `watchlist_${today}.csv`);
};

const downloadVerticalPriorities = async (vertical_priorities) => {
  const today = getToday();
  const csvData = convertToCSV(vertical_priorities);
  downloadCSV(csvData, `vertical_priorities_${today}.csv`);
};

export default function Watchlist() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [verticalFilters, setVerticalFilters] = useState(VERTICAL_FILTERS);
  const [userId, setUserId] = useState(null);
  const [watchlist, setWatchlist] = useState(null);
  const [verticalPriorities, setVerticalPriorities] = useState(null);
  const [filterValue, setFilterValue] = useState('');
  const isMobile = useIsMobile();

  const getUserId = async () => {
    const localUser = JSON.parse(getLocalValue(AUTH_TOKEN_KEY));
    const { user_id, email } = localUser;
    if (user_id) return user_id;
    const user = await getUsers().then((users) => users.find((u) => u.email === email));
    return user.user_id;
  };

  const getWatchlist = async () => {
    getUserId().then((user_id) => {
      setUserId(user_id);
      getUserWatchlistCompanies(user_id).then((companies) => {
        if (companies.length > 0) setWatchlist(companies);
        dispatch({ type: UPDATE_COMPANIES, payload: { index: 0, companies } });
      });
    });
  };

  const getVerticalPriorities = async () => {
    getVerticalPriorityCompanies().then((companies) => {
      if (companies.length > 0) setVerticalPriorities(companies);
      dispatch({ type: UPDATE_COMPANIES, payload: { index: 1, companies } });
    });
  };

  const hasVertical = useCallback(
    (c) => {
      const verticalNames = verticalFilters.filter((v) => v.active).map((v) => v.name);
      if (verticalNames.includes('All')) return true;
      const { tv_vertical } = c;
      if (!tv_vertical) return false;
      const companyVerticals = tv_vertical.split(', ');
      for (let i = 0; i < companyVerticals.length; i += 1) {
        if (verticalNames.includes(companyVerticals[i])) {
          return true;
        }
      }

      return false;
    },
    [verticalFilters],
  );

  const changeVertical = (value) => {
    const newVertical = value;
    verticalFilters.forEach((v) => (v.active = newVertical === v.name));
    setVerticalFilters([...verticalFilters]);
  };

  useEffect(() => {
    getWatchlist();
    getVerticalPriorities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredCompanies = useMemo(() => {
    let { companies } = state.find((l) => l.active);

    companies = companies.map((c) => {
      c.months_since_last_investment = calcMonthsSinceInvestment(c.last_investment_date);
      return c;
    });

    return companies
      .filter((c) => hasVertical(c))
      .filter((c) => {
        if (filterValue.length === 0 || filterValue === '') return true;
        return c.company_name.toLowerCase().includes(filterValue.toLowerCase());
      });
  }, [state, hasVertical, filterValue]);

  const activePanel = useMemo(() => state.find((l) => l.active)?.type, [state]);

  const noResultsCheck = useMemo(() => {
    const { companies } = state.find((l) => l.active);

    return filteredCompanies.length === 0 && companies?.length > 0;
  }, [filteredCompanies, state]);

  const headerButtons = useMemo(() => {
    if (isMobile) return null;

    return activePanel === 'watchlist' ? (
      <Button
        disabled={!watchlist}
        onClick={() => downloadWatchlist(watchlist)}
        className="prospect-stats-btn"
      >
        Download Watchlist
      </Button>
    ) : (
      <Button
        disabled={!verticalPriorities}
        onClick={() => downloadVerticalPriorities(verticalPriorities)}
        className="prospect-stats-btn"
      >
        Download Vertical Priorities
      </Button>
    );
  }, [activePanel, verticalPriorities, watchlist, isMobile]);

  return (
    <div>
      <Helmet>
        <title>Watchlist - TV CRM</title>
      </Helmet>

      <PageHeader title="Watchlist Companies" button={headerButtons} />

      <Container className="container page-container container--watchlist">
        <div className="toggle-view-btns btn-group flagged-btns tv-card" role="group">
          {state.map((l) => (
            <button
              key={`status-${l.label}`}
              type="button"
              onClick={() => dispatch({ type: TOGGLE })}
              className={`btn btn-default${l.active ? ' active' : ''}`}
              value={l.label}
            >
              {l.label} ({l.numCompanies})
            </button>
          ))}
        </div>

        <FeedFilter
          withVerticalFilter
          handleOnVerticalChange={changeVertical}
          withSearchInputFilter
          handleSearchInputChange={setFilterValue}
          padding={isMobile ? '10px 24px' : '12px 34px'}
          margin={isMobile ? '0 0 10px 0' : '20px 0 20px 0'}
        />

        <ListCompanyTable
          companies={filteredCompanies}
          isProspect
          userId={userId}
          tableMaxHeightMobile="280px"
          columns={activePanel === 'watchlist' ? WATCHLIST_COLUMNS : VERTICAL_PRIORITY_COLUMNS}
          defaultSortKey={
            activePanel === 'watchlist' ? 'date_added' : 'vertical_priority_date_added'
          }
          noResults={noResultsCheck}
          withMargin={false}
        />
      </Container>
    </div>
  );
}
