import React, { useState, useEffect, useMemo, useCallback } from 'react';
import ListPanel from './ListPanel';
import '../styles/components/ListCompanyTable.css';
import { getSubverticals, getUserMemos } from '../BackendAPI';
import { TableHeader, Table, TableBody } from './primitives/Table';
import { Flex, Loader } from './primitives';
import useIsMobile from '../hooks/useIsMobile';
import { compare } from '../util';

export default function ListCompanyTable({
  userId,
  companies,
  columns,
  isProspect,
  defaultSortKey,
  defaultIsAsc,
  noResults,
  hasError,
  tableMaxHeight = '280px',
  tableMaxHeightMobile = '320px',
  withMargin = true,
  innerTableWidth,
  withProspectButton = false,
  refreshFeed,
  users,
}) {
  const isMobile = useIsMobile();
  const [sortKey, setSortKey] = useState('company_name');
  const [isAsc, setIsAsc] = useState(true);
  const [subverticals, setSubverticals] = useState([]);
  const [userMemos, setUserMemos] = useState([]);

  const updateSubverticals = useCallback(() => {
    const hasSubverticals = columns.map((c) => c.key).includes('subvertical');
    if (hasSubverticals) {
      getSubverticals().then((data) => setSubverticals(data));
    }
  }, [columns]);

  const updateUserMemos = useCallback(() => {
    const hasUserMemos = columns.map((c) => c.key).includes('my_memos');
    if (hasUserMemos && userId) {
      getUserMemos(userId).then((data) => setUserMemos(data));
    }
  }, [columns, userId]);

  useEffect(() => {
    if (defaultSortKey !== null) {
      setSortKey(defaultSortKey);
    }
    if (defaultIsAsc !== null) {
      setIsAsc(defaultIsAsc);
    }
    updateSubverticals();
  }, [defaultIsAsc, defaultSortKey, updateSubverticals]);

  useEffect(() => {
    updateUserMemos();
  }, [userId, updateUserMemos]);

  const sort = (key) => {
    if (sortKey === key) {
      setIsAsc(!isAsc);
    } else {
      setIsAsc(true);
      setSortKey(key);
    }
  };

  const sortedCompanies = useMemo(() => {
    const dataSorted = companies.sort((a, b) => compare(a[sortKey], b[sortKey]));

    if (!isAsc) {
      dataSorted.reverse();
    }

    return dataSorted;
  }, [companies, isAsc, sortKey]);

  const loadingState = useMemo(() => {
    if (hasError && !noResults) {
      return (
        <h6>
          There was an issue. Please refresh and try again. If the issue persists reach out to the
          IT team.
        </h6>
      );
    }
    if (noResults && !hasError) {
      return <h6>No results match your filter or search</h6>;
    }
    return <Loader />;
  }, [hasError, noResults]);

  return (
    <div className={`company-panel-list ${isProspect ?? 'prospect-list'}`}>
      <Table
        maxHeightDelta={tableMaxHeight}
        maxHeightMobileDelta={tableMaxHeightMobile}
        withMargin={withMargin}
      >
        <TableHeader
          headerColumns={columns}
          sort={sort}
          sortKey={sortKey}
          isAsc={isAsc}
          width={innerTableWidth}
        />
        {!companies || companies.length === 0 ? (
          <Flex
            fill
            align="center"
            justify="center"
            height={`calc(100vh - ${isMobile ? tableMaxHeight : tableMaxHeightMobile})`}
          >
            {loadingState}
          </Flex>
        ) : (
          <TableBody width={innerTableWidth}>
            {sortedCompanies.map((c) => (
              <ListPanel
                company={c}
                key={c.permalink}
                columns={columns}
                subverticals={subverticals}
                userMemos={userMemos}
                userId={userId}
                withProspectButton={withProspectButton}
                refreshFeed={refreshFeed}
                users={users}
              />
            ))}
          </TableBody>
        )}
      </Table>
    </div>
  );
}
