import React, { useState, useEffect, useCallback, memo, useMemo } from 'react';
import styled from 'styled-components';
import slugify from 'slugify';
import { format, isAfter } from 'date-fns';
import renderHTML from 'react-render-html';
import HoverLink from './HoverLink';
import {
  displayInvestmentType,
  truncate,
  sanitizeAndCombineVerticals,
  capitalize,
  removeDuplicatesFromString,
  displayMoney,
} from '../util';
import { ProspectScoreV2ContextProvider } from '../contexts/ProspectScoreV2Context';
import {
  getCompanySubverticals,
  getCompanyInvestors,
  getCompanyHarmonicInvestors,
  getCompanyProspects,
  getProspectScores,
  getProspectScoresV2,
} from '../BackendAPI';
import { combineAndRemoveDuplicates } from './company/CompanyKeyInvestors';
import { ProspectContextProvider } from '../contexts/ProspectContext';
import ProspectInlineButton, {
  Container as ProspectButtonContainer,
} from './prospect/ProspectInlineButton';
import ProspectModals from './prospect/ProspectModals';
import UserMemo from './UserMemo';
import { VERT_MAP } from '../constants';
import { TableRow } from './primitives/Table';
import { Pill, Button, Icon } from './primitives';
import captureError from '../utils/sentry';
import { CompanyVerticalContextProvider } from '../contexts/CompanyVerticalContext';

export const ScrollContainer = styled.div`
  max-height: 70px;
  width: 100%;
  overflow: scroll;
  padding-left: 0 !important;
  padding-bottom: 6px;
  & > p {
    padding: 0 !important;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const RowDisplayValue = memo(
  ({
    columnKey,
    company,
    headcount,
    userId,
    userMemos,
    subverticalString,
    keyInvestorsString,
    onWatchlist,
  }) => {
    let companyKeyValue = company[columnKey];

    if (columnKey === 'date_added' || columnKey === 'vertical_priority_date_added') {
      const isAfterLaunchDate = isAfter(new Date(companyKeyValue), new Date(2023, 4, 30));
      if (isAfterLaunchDate) {
        return format(new Date(companyKeyValue), 'M/d/yy');
      }
      return '-';
    }

    if (
      columnKey === 'status_updated_date' ||
      columnKey === 'last_investment_date' ||
      columnKey === 'initial_funding_date' ||
      columnKey === 'round_date'
    ) {
      if (companyKeyValue) {
        return format(new Date(companyKeyValue), 'M/d/yy');
      }
      return '-';
    }

    if (columnKey === 'key_investors' || columnKey === 'short_description') {
      if (columnKey === 'key_investors' && companyKeyValue) {
        if (Array.isArray(companyKeyValue)) {
          companyKeyValue = companyKeyValue.join(', ');
        }
        companyKeyValue = removeDuplicatesFromString(companyKeyValue);
      }

      if (columnKey === 'key_investors' && keyInvestorsString && keyInvestorsString !== '-') {
        companyKeyValue = keyInvestorsString;
      }

      if (companyKeyValue) {
        return (
          <ScrollContainer>
            <p>{companyKeyValue}</p>
          </ScrollContainer>
        );
      }
      return '-';
    }

    if (columnKey === 'subvertical') {
      if (Array.isArray(companyKeyValue) && companyKeyValue?.length > 0) {
        return companyKeyValue.map((a) => (
          <span className="vertical-item" key={a}>
            {a}
          </span>
        ));
      }

      if (subverticalString === '-') return subverticalString;
      return subverticalString.split(',').map((a) => (
        <span className="vertical-item" key={a}>
          {a}
        </span>
      ));
    }

    if (columnKey === 'emp_growth') {
      return headcount;
    }

    if (columnKey === 'my_memos') {
      return <UserMemo userMemos={userMemos} permalink={company.permalink} userId={userId} />;
    }

    if (columnKey === 'last_investment_type' || columnKey === 'last_round') {
      return displayInvestmentType(company[columnKey]);
    }

    if (columnKey === 'raised_amount_usd') {
      if (!company[columnKey]) return '-';
      return displayMoney(company[columnKey]);
    }

    if (columnKey === 'tv_vertical') {
      const verticals = sanitizeAndCombineVerticals(company);
      return verticals
        ?.map((a) => {
          if (VERT_MAP[a.trim()]) {
            return (
              <span className={`vertical-item ${slugify(a).toLowerCase()}`} key={a}>
                {VERT_MAP[a.trim()]}
              </span>
            );
          }

          return undefined;
        })
        .filter((a) => Boolean(a));
    }

    if (columnKey === 'code' || columnKey === 'vertical_priority_added_by') {
      if (companyKeyValue && companyKeyValue !== 'N/A') {
        return <span>{companyKeyValue}</span>;
      }
      return '-';
    }

    if (columnKey === 'days_outstanding') {
      if (parseInt(companyKeyValue, 10) >= 60) {
        return <span className="red-outstanding">{companyKeyValue} days</span>;
      }
      return <span>{companyKeyValue} days</span>;
    }

    if (columnKey === 'tv_company_score') {
      if (parseInt(companyKeyValue, 10) >= 7) {
        return <span className="green-score">{companyKeyValue}</span>;
      }
      return companyKeyValue;
    }

    if (columnKey === 'months_since_last_investment') {
      if (companyKeyValue === 0) return '<1 month';
      if (companyKeyValue === 1) return '1 month';
      if (companyKeyValue <= 24) return `${companyKeyValue} months`;
      return <span className="green-score">2+ years</span>;
    }

    if (columnKey === 'name') {
      const { permalink, company_permalink } = company;
      return (
        <HoverLink
          orgType={'investor'}
          permalink={permalink ?? company_permalink}
          companyName={companyKeyValue}
        />
      );
    }

    if (columnKey === 'company_name') {
      const { permalink, company_permalink } = company;
      return (
        <HoverLink
          orgType={company.org_type ?? 'company'}
          permalink={permalink ?? company_permalink}
          companyName={companyKeyValue}
        />
      );
    }

    if (columnKey === 'org_type') {
      if (companyKeyValue) {
        return `${companyKeyValue.charAt(0).toUpperCase() + companyKeyValue.slice(1)}`;
      }
      return '-';
    }

    if (columnKey === 'major_status') {
      if (companyKeyValue) {
        if (companyKeyValue === 'Active') {
          return <Pill value="Prospect" />;
        }
        return <Pill value={capitalize(companyKeyValue)} />;
      }
      if (company.vertical_priority) return <Pill value="Vertical Priority" />;
      if (onWatchlist) return <Pill value="Watchlist" />;
      return '-';
    }

    if (columnKey === 'status') {
      if (companyKeyValue) {
        return <span className="status-text">{companyKeyValue}</span>;
      }
      return '-';
    }

    if (columnKey === 'domain' && companyKeyValue) {
      return (
        <a href={`https://${companyKeyValue}`} rel="noreferrer" target="_blank">
          {companyKeyValue}
        </a>
      );
    }

    if (columnKey === 'last_note') return renderHTML(companyKeyValue);

    return companyKeyValue === 'N/A' || !companyKeyValue ? '-' : truncate(String(companyKeyValue));
  },
);

const ListPanel = ({
  company,
  columns,
  subverticals,
  userMemos,
  userId,
  watchlistData,
  withProspectButton,
  // refreshFeed,
  users,
}) => {
  const [subverticalString, setSubverticalString] = useState('-');
  const [keyInvestorsString, setKeyInvestorsString] = useState('-');
  const [headcount, setHeadcount] = useState('-');
  const [onWatchlist, setOnWatchlist] = useState(false);
  const [prospectInfo, setProspectInfo] = useState(null);
  const [prospectScores, setProspectScores] = useState(null);
  const [prospectScoresV2, setProspectScoresV2] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const updateSubverticals = useCallback(async () => {
    const hasSubverticals = columns.map((c) => c.key).includes('subvertical');
    if (hasSubverticals) {
      if (company.subverticals) {
        setSubverticalString(company.subverticals.join(','));
      } else if (company.subvertical) {
        if (Array.isArray(company.subvertical)) {
          setSubverticalString(company.subvertical.join(','));
        } else {
          setSubverticalString(company.subvertical);
        }
      } else {
        try {
          const companySubVerticals = await getCompanySubverticals(company.permalink);
          const isGPT = companySubVerticals.some((s) => s.is_gpt);

          if (companySubVerticals.length > 0 && subverticals.length > 0) {
            const newStr = companySubVerticals
              .map((csv) => {
                const subvertRow = subverticals.find((sv) => sv.id === csv.subvertical_id);
                return `${subvertRow.label}${isGPT ? ' (GPT)' : ''}`;
              })
              .join(',');
            setSubverticalString(newStr);
          }
        } catch (err) {
          captureError(`Error getting subverticals for ${company.permalink}`, err);
        }
      }
    }
  }, [columns, company, subverticals]);

  const updateKeyInvestors = useCallback(async () => {
    const hasKeyInvestors = columns.map((c) => c.key).includes('key_investors');
    if (hasKeyInvestors) {
      if (company.key_investors) {
        if (Array.isArray(company.key_investors)) {
          setKeyInvestorsString(company.key_investors.join(', '));
        } else {
          setKeyInvestorsString(company.key_investors);
        }
      } else {
        try {
          const data = await getCompanyInvestors(company.permalink);
          const harmonicInvestors = await getCompanyHarmonicInvestors(company.permalink);
          let keyInvestors = combineAndRemoveDuplicates(data, harmonicInvestors);

          if (keyInvestors?.length > 0) {
            keyInvestors = keyInvestors
              .sort((a, b) => a.investor_score - b.investor_score)
              .map((i) => i.investor_name)
              .join(', ');

            setKeyInvestorsString(keyInvestors);
          }
        } catch (error) {
          captureError(`Error getting subverticals for ${company.permalink}`, error);
        }
      }
    }
  }, [columns, company.permalink, company.key_investors]);

  // const updateHeadcount = useCallback(async () => {
  //   const hasHeadcount = columns.map((c) => c.key).includes('emp_growth');
  //   if (hasHeadcount) {
  //     const { permalink } = company;

  //     try {
  //       const headcountData = await getHeadcount(
  //         `https://www.crunchbase.com/organization/${permalink}`,
  //       );

  //       const pctValue = headcountData?.ago180d?.percentChange;

  //       if (pctValue) {
  //         setHeadcount(`${pctValue.toFixed(1)}%`);
  //       }
  //     } catch (err) {
  //       captureError(`Error getting headcount for ${company.permalink}`, err);
  //     }
  //   }
  // }, [columns, company]);

  const getWatchlist = useCallback(async () => {
    const checkForWatchlist = columns.map((c) => c.key).includes('major_status');
    if (checkForWatchlist && watchlistData) {
      const { permalink } = company;
      try {
        const isOnWatchlist = watchlistData?.find((w) => w.permalink === permalink);
        setOnWatchlist(Boolean(isOnWatchlist));
      } catch (err) {
        captureError(err);
      }
    }
  }, [columns, watchlistData, company]);

  const updateProspects = useCallback(async () => {
    try {
      const data = await getCompanyProspects(company.permalink);
      const prospectData = data[0];

      if (prospectData) {
        setProspectInfo(prospectData);
        const prospectScoresData = await getProspectScores(prospectData.id);
        setProspectScores(prospectScoresData);
        const prospectScoresV2Data = await getProspectScoresV2(prospectData.id);
        setProspectScoresV2(prospectScoresV2Data);
      }
    } catch (err) {
      captureError(err);
    }
  }, [company]);

  useEffect(() => {
    updateSubverticals();
    getWatchlist();
    updateKeyInvestors();
    if (withProspectButton) updateProspects();
  }, [
    subverticals,
    columns,
    updateSubverticals,
    getWatchlist,
    updateKeyInvestors,
    updateProspects,
    withProspectButton,
  ]);

  const showProspectButton = useMemo(() => {
    const og =
      withProspectButton && prospectInfo && prospectScores && users && company && prospectScoresV2;
    return og;
  }, [withProspectButton, prospectInfo, prospectScores, prospectScoresV2, users, company]);

  return (
    <TableRow
      className="list-company-row"
      isRecent={company.is_recent}
      key={company.permalink}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {columns.map((column, index) => (
        <p className={`column col${index} ${column.colClass}`} key={column.key}>
          <RowDisplayValue
            columnKey={column.key}
            company={company}
            headcount={headcount}
            userId={userId}
            userMemos={userMemos}
            subverticalString={subverticalString}
            keyInvestorsString={keyInvestorsString}
            onWatchlist={onWatchlist}
          />
        </p>
      ))}
      {withProspectButton && !showProspectButton && (
        <ProspectButtonContainer>
          <Button disabled>
            <Icon name="plus" height="15px" width="15px" color="#cccccc" />
          </Button>
        </ProspectButtonContainer>
      )}
      {showProspectButton && (
        <CompanyVerticalContextProvider>
          <ProspectContextProvider
            permalink={company.permalink}
            companySummary={company}
            users={users}
            prospectInfo={prospectInfo}
            prospectScores={prospectScores}
          >
            <ProspectScoreV2ContextProvider prospectScores={prospectScoresV2}>
              <ProspectInlineButton isHovered={isHovered} loading={false} />
              <ProspectModals withVerticals={false} />
            </ProspectScoreV2ContextProvider>
          </ProspectContextProvider>
        </CompanyVerticalContextProvider>
      )}
    </TableRow>
  );
};

export default memo(ListPanel);
