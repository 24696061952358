/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect, memo, useCallback } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { isAfter, format } from 'date-fns';
import { addLink, getDocuments, uploadFile, deleteFile, BACKEND_API } from '../../BackendAPI';
import { getAccessToken, getUserEmail } from '../../utils/Auth';
import '../../styles/components/CompanyDocuments.css';
import captureError from '../../utils/sentry';
import { Loader } from '../primitives';

const DisplayDocument = ({
  document,
  openDocument,
  userEmail,
  deleteDocument,
  isSingleDocument,
}) => {
  const { document_id, url, document_name, uuid, date_added, add_time, added_by } = document;

  if (!url) {
    const accessToken = getAccessToken().split(' ')[1];
    const viewUrl = `${BACKEND_API}/company/view_document/${uuid}/${accessToken}`;
    const downloadUrl = `${BACKEND_API}/company/download_document/${uuid}/${accessToken}`;

    return (
      <li className="document-li" key={document_id}>
        <div>
          <a href="#" onClick={openDocument} data-url={viewUrl}>
            {document_name}
          </a>
          <a className="icon-button" href={downloadUrl}>
            <i className="fa fa-download" />
          </a>
          {((added_by && added_by === userEmail) || isSingleDocument) && (
            <button
              type="button"
              className="icon-button"
              onClick={() => deleteDocument(document_id)}
            >
              <i className="fa fa-trash with-margin" />
            </button>
          )}
        </div>
        {add_time ? (
          <p className="document-date-added">Added {format(new Date(add_time), 'M/dd/yy')}</p>
        ) : (
          <>
            {date_added && isAfter(new Date(date_added), new Date('6/27/2023')) && (
              <p className="document-date-added">Added {format(new Date(date_added), 'M/dd/yy')}</p>
            )}
          </>
        )}
      </li>
    );
  }

  return (
    <li className="document-li" key={document_id}>
      <div>
        <a href={url} target="_blank" rel="noreferrer">
          {document_name}
        </a>
        {((added_by && added_by === userEmail) || isSingleDocument) && (
          <button type="button" className="icon-button" onClick={() => deleteDocument(document_id)}>
            <i className="fa fa-trash" />
          </button>
        )}
      </div>
      {add_time ? (
        <p className="document-date-added">Added {format(new Date(add_time), 'M/dd/yy')}</p>
      ) : (
        <>
          {date_added && isAfter(new Date(date_added), new Date('6/27/2023')) && (
            <p className="document-date-added">Added {format(new Date(date_added), 'M/dd/yy')}</p>
          )}
        </>
      )}
    </li>
  );
};

const CompanyDocuments = ({
  company,
  isSingleDocument,
  documentsFromProps,
  handleDeleteDocument,
}) => {
  const [documents, setDocuments] = useState(documentsFromProps ?? []);
  const [showModal, setShowModal] = useState(false);
  const [showDocument, setShowDocument] = useState(false);
  const [documentUrl, setDocumentUrl] = useState(null);
  const [name, setName] = useState('');
  const [url, setUrl] = useState('');
  const [error, setError] = useState(null);
  const [urlError, setUrlError] = useState(null);
  const [loading, setLoading] = useState(false);
  const userEmail = getUserEmail();

  const fetchDocuments = useCallback(async () => {
    try {
      const docs = await getDocuments(company);
      setDocuments(docs);
    } catch (err) {
      captureError(`Failed to fetch ${company} documents :${err}`);
    }
  }, [company]);

  useEffect(() => {
    if (!documentsFromProps) fetchDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFileUpload = useCallback(
    async (event) => {
      setLoading(true);
      const file = event.target.files[0];
      const data = new FormData();
      data.append('file', file);

      try {
        await uploadFile(data, company, userEmail);
        await fetchDocuments();
      } catch (err) {
        setError('There was an error uploading your file. Please try again.');
        captureError(`Failed to upload ${company} documents :${err}`);
      } finally {
        setLoading(false);
      }
    },
    [company, fetchDocuments, userEmail],
  );

  const deleteDocument = useCallback(
    async (documentId) => {
      try {
        await deleteFile(documentId);
        if (handleDeleteDocument) {
          await handleDeleteDocument(documentId);
        } else {
          await fetchDocuments();
        }
      } catch (err) {
        setError('There was an error deleting your file. Please try again.');
        captureError(`Failed to delete ${company} documents :${err}`);
      }
    },
    [company, fetchDocuments, handleDeleteDocument],
  );

  const openDocument = useCallback((e) => {
    e.preventDefault();
    setShowDocument(true);
    setDocumentUrl(e.target.getAttribute('data-url'));
  }, []);

  const addLinkToDb = useCallback(
    async (e) => {
      setLoading(true);
      e.preventDefault();

      if (url.slice(0, 7) !== 'http://' && url.slice(0, 8) !== 'https://') {
        setUrlError('Please submit properly formatted url');
        return;
      }

      try {
        setShowModal(false);

        await addLink(company, name, url, 'link', userEmail);
        await fetchDocuments();
      } catch (err) {
        setError('There was an error adding your link. Please try again.');
        captureError(err);
      } finally {
        setName('');
        setUrl('');
        setLoading(false);
      }
    },
    [company, fetchDocuments, name, url, userEmail],
  );

  if (isSingleDocument) {
    const document = documents?.[0] ?? null;
    if (!document) {
      return null;
    }

    return (
      <div className="company-documents content-container card single-document">
        <ul className="list-unstyled list-spaced">
          <DisplayDocument
            key={document.uuid}
            document={document}
            openDocument={openDocument}
            deleteDocument={deleteDocument}
            userEmail={userEmail}
            isSingleDocument
          />
        </ul>
      </div>
    );
  }

  return (
    <div className="company-documents content-container card">
      <div className="card-block">
        <h3>Documents</h3>

        <ul className="list-unstyled list-spaced">
          {documents?.map((doc) => (
            <DisplayDocument
              key={doc.uuid}
              document={doc}
              openDocument={openDocument}
              deleteDocument={deleteDocument}
              userEmail={userEmail}
            />
          ))}
          {loading && (
            <li>
              <Loader />
            </li>
          )}
        </ul>

        <label className="tv-button btn-file">
          + Add File
          <input type="file" style={{ display: 'none' }} onChange={handleFileUpload} />
        </label>

        <label className="tv-button btn-file">
          + Add Link
          <input style={{ display: 'none' }} onClick={() => setShowModal(true)} />
        </label>

        {error ? <p className="red-outstanding">{error}</p> : ''}

        <Modal
          backdrop="static"
          show={showDocument}
          onHide={() => setShowDocument(false)}
          className="tv-modal tv-company-modal documents-modal"
        >
          <Modal.Header>
            <Modal.Title>View Document</Modal.Title>
            <Button className="modal-close" onClick={() => setShowDocument(false)}>
              <i className="fa fa-times" />
            </Button>
          </Modal.Header>
          <Modal.Body>
            <iframe src={documentUrl} width="100%" height="100%" />
          </Modal.Body>
        </Modal>

        <Modal
          backdrop="static"
          show={showModal}
          onHide={() => setShowModal(false)}
          className="tv-modal tv-company-modal"
        >
          <Modal.Header>
            <Modal.Title>New Link</Modal.Title>
            <Button className="modal-close" onClick={() => setShowModal(false)}>
              <i className="fa fa-times" />
            </Button>
          </Modal.Header>
          <Modal.Body>
            <ul className="list-unstyled list-spaced">
              <li>
                <label>
                  <input
                    className="form-control"
                    name="name"
                    value={name}
                    placeholder="Name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </label>
              </li>
              <li>
                <label>
                  <input
                    className="form-control"
                    name="url"
                    value={url}
                    placeholder="Url"
                    onChange={(e) => setUrl(e.target.value)}
                  />
                </label>
              </li>
            </ul>
            {urlError ? <p className="red-outstanding">{urlError}</p> : ''}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={addLinkToDb}>Add</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default memo(CompanyDocuments);
