import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { getList, subscribe, unsubscribe } from '../BackendAPI';
import { getUserEmail } from '../utils/Auth';

import ListCompanyTable from '../components/ListCompanyTable';
import ListFeedView from '../components/ListFeedView';

import { isRecent } from '../util';

const PROSPECT = 36;
const PORTFOLIO = 44;

class ListProfile extends Component {
  state = {
    isSubscribed: false,
    showSubscribeButton: true,
    name: '',
    description: '',
    listId: '',
    stats: {},
    feed: [],
    companies: [],
    companyView: true,
  };

  getFeed = () => {
    getList(this.props.listId, getUserEmail()).then((data) => {
      const { companies, feed, display_stats, stats, list_id, isSubscribed, description } = data;
      let { name } = data;
      if (name === 'Prospect') name = 'Prospects';
      this.setState({ companies, feed, name, isSubscribed, listId: list_id, description });
      if (!display_stats) return;
      this.setState({ stats });
      if (list_id === PROSPECT || list_id === PORTFOLIO) {
        this.setState({ showSubscribeButton: false });
      }
    });
  };

  componentDidMount() {
    this.getFeed();
  }

  getQuarter = () => {
    const d = new Date();
    return Math.floor(d.getMonth() / 3) + 1;
  };

  toggleMode = (e) => {
    const { value } = e.target;
    if (value === 'company' && this.state.companyView) return;
    if (value === 'feed' && !this.state.companyView) return;
    this.setState({ companyView: !this.state.companyView });
  };

  toggleSubscribe = () => {
    const userEmail = getUserEmail();
    const { isSubscribed, listId } = this.state;

    if (isSubscribed) {
      unsubscribe(userEmail, listId);
    } else {
      subscribe(userEmail, listId);
    }
    this.setState({ isSubscribed: !isSubscribed });
  };

  // <ListCompanyTable companies={companies} isProspect={isProspect}> : <ListCompanyTable companies={companies} isProspect={isProspect}>}

  render() {
    // if (Number(this.props.listId) === PROSPECT) return (<Redirect to={{ pathname: '/prospects' }}/>)
    const { companies, name, companyView, feed, showSubscribeButton, isSubscribed, description } =
      this.state;
    const isProspect = this.state.listId === PROSPECT;
    companies.forEach((v) => {
      v.isRecent = isRecent(v.add_time);
    });

    // set all to recent
    feed.forEach((v) => {
      v.isRecent = isRecent(v.feed_time);
    });
    // calculate num updates
    const numRecent = feed.filter((v) => v.isRecent).length;

    return (
      <div>
        <Helmet>
          <title>{name ?? 'List Profile'} - TV CRM</title>
        </Helmet>

        <div className="container-page-header">
          <div className="container page-header">
            <div className="list-name-description">
              <h2>{name}</h2>
              <span>{description}</span>
            </div>

            <div className="toggle-view-btns btn-group" role="group" aria-label="...">
              <button
                type="button"
                className={`btn btn-default${companyView ? ' active' : ''}`}
                value="company"
                onClick={this.toggleMode}
              >
                Company
              </button>
              <button
                type="button"
                className={`btn btn-default${!companyView ? ' active' : ''}`}
                value="feed"
                onClick={this.toggleMode}
              >
                Feed
              </button>
            </div>

            {showSubscribeButton ? (
              <div className="subscribe-button-container">
                <button
                  type="button"
                  className={`tv-button subscribe-button${isSubscribed ? ' subscribed' : ''}`}
                  onClick={this.toggleSubscribe}
                >
                  {isSubscribed ? 'Subscribed' : 'Subscribe'}
                </button>
              </div>
            ) : (
              ''
            )}

            <div className={`list-actions ${this.state.stats.current_num ? '' : ' hidden'}`}>
              <span className="list-meta">
                <span className="list-meta-item">
                  <strong>{numRecent}</strong>Weekly Update{numRecent === 1 ? '' : 's'}
                </span>
                <span className="list-meta-item">
                  <strong>{this.state.stats.current_num}</strong>
                  {this.state.stats.current_num === 1 ? 'Company' : 'Companies'}
                </span>
                <span className="list-meta-item">
                  <strong>{this.state.stats.quarter_num}</strong>
                  {this.state.stats.quarter_num === 1 ? 'Company' : 'Companies'} in Q
                  {this.getQuarter()}
                </span>
                <span className="list-meta-item">
                  <strong>{this.state.stats.quarter_run_rate}</strong>Run Rate
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="container">
          <div>
            {companyView ? (
              <ListCompanyTable companies={companies} isProspect={isProspect} />
            ) : (
              <ListFeedView feed={feed} refreshFeed={this.getFeed} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ListProfile;
